<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col align="center">
          <h4 text-center>Your Payment Has Been Cancelled.</h4>
          <v-btn text color="blue" to="/">Return to Homepage</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Cancel",
  components: {},
  metaInfo() {
    return {
      meta: [{ vmid: "robots", name: "robots", content: "none" }]
    };
  }
};
</script>
